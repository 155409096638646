import React from "react"
import Img from "gatsby-image"

import styles from "./project-item.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ProjectItem = ({ imgFluid, name, githubUrl, demoUrl, description }) => {
  return (
    <div className={styles["project-item"]}>
      <Img fluid={imgFluid} className={styles.image} />
      <div className={styles.name}>
        <b>{name}</b>
      </div>
      <div className={styles.description}>{description}</div>
      <div className={styles["project-links"]}>
        <a
          href={githubUrl}
          className={`${styles.githubUrl} ${styles.linkUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={["fab", "github"]} />
          <span>Github</span>
        </a>

        <a
          href={demoUrl}
          className={`${styles.demoUrl} ${styles.linkUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={"eye"} />
          <span>Demo</span>
        </a>
      </div>
    </div>
  )
}

export default ProjectItem
