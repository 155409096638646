import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import ProjectItem from "../project-item/project-item.component"

import styles from "./project-preview.module.scss"

const ProjectPreview = ({ limit }) => {
  const data = useStaticQuery(graphql`
    query MyProjects {
      allProjectsJson(limit: 1) {
        nodes {
          projectList {
            name
            description
            githubUrl
            demoUrl
            image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 150, quality: 100) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.allProjectsJson.nodes[0].projectList

  return (
    <div className={styles["project-preview"]}>
      {projects.slice(0, limit).map((project, index) => {
        return (
          <ProjectItem
            key={index}
            {...project}
            imgFluid={project.image.childImageSharp.fluid}
          />
        )
      })}
    </div>
  )
}

export default ProjectPreview
